<template>
  <div class="card">
    <div :class="`bg-${widgetColor}`" class="card-header border-0 py-5">
      <h3 class="card-title fw-bolder text-white">Finance Statistics</h3>
      
    </div>
    <div class="card-body p-0">
      <apexchart
        :class="`bg-${widgetColor}`"
        class="mixed-widget-2-chart card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        height="200"
        type="area"
      ></apexchart>
      <div class="card-p mt-n20 position-relative">
        <div class="row m-0">
          <router-link to="/student/student-list" class="col bg-light-warning px-6 py-8 rounded-2 me-7">
            <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
              <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
            </span>
             <span class="text-primary fw-bold fs-6">Student List</span>
          </router-link>
          <a
              class="col bg-light-danger px-6 py-8 rounded-2 me-7"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#modal_create_record"
              id="record_create_button">
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <inline-svg src="media/icons/duotune/arrows/arr075.svg" />
            </span>
            <span class="text-danger fw-bold fs-6 mt-2"> Create finance transaction </span>
          </a>
          <router-link to="/management/invoice/create" class="col bg-light-success px-6 py-8 rounded-2">
            <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
              <inline-svg src="media/icons/duotune/communication/com010.svg" />
            </span>
            <span class="text-success fw-bold fs-6 mt-2"> Create Invoice </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import _, { values } from "lodash";

export default defineComponent({
  name: "widget-1",
  components: {
  },
  props: {
    widgetClasses: String,
    widgetColor: String,
    strokeColor: String,
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const color = getCSSVariableValue("--bs-" + props.widgetColor);    
    const strokeColor = ref(props.strokeColor);
    const endDate = moment().endOf('week').format('YYYY-MM-DD');
    const startDate = moment().subtract(6,'w').startOf('week').format('YYYY-MM-DD');    
    
    let dateRange = [] as any;
    for(var i = 6; i>=0; i--){
      dateRange.push(moment().subtract(i,'w').startOf('week').format('MM/DD/YYYY') +'~'+moment().subtract(i,'w').endOf('week').format('MM/DD/YYYY'))
    }

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: strokeColor.value,
          opacity: 0.5,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 0,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [strokeColor.value],
      },
      xaxis: {
        categories: dateRange,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: borderColor,
            width: 1,
            dashArray: 3,
          },
        },
      },
      yaxis: {
        min: -5000,
        max: 5000,
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "$" + val;
          },
        },
        marker: {
          show: false,
        },
      },
      markers: {
        colors: [color],
        strokeColor: [strokeColor.value],
        strokeWidth: 3,
      },
    };

    const series = ref([
      {
        name: "Total",
        data: [30, 45, 32, 70, 40, 40, 40],
      }
    ]);

    const getWeeklyData = async() =>{
      await new Promise<void>((resolve, reject) => {   
            ApiService.setHeader();         
            ApiService.get("finance/records/"+startDate+"/"+endDate)
                .then(({ data }) => {
                    series.value[0].data = [];               
                    let groupedData = Object.entries(_.groupBy(data.data, data=> moment(data.record_date).week())).map(key => {return{key: +key[0], data: key[1]};});
                    
                    for(var i = 6; i>=0; i--){
                      let cWeek = moment().subtract(i,'w').week();
                      let cWeekData = groupedData.filter(x=>x.key == cWeek);
                      let cWeekDataTotal = 0;
                      if(cWeekData && cWeekData.length > 0){
                        cWeekData[0].data.forEach(record => {
                            let value = parseFloat(record.amount);                                
                            if(record.record_type == 'Deposit' || record.record_type == 'Credit' || record.record_type == 'Payable'){
                                cWeekDataTotal = cWeekDataTotal + value;
                            }
                            else{
                                cWeekDataTotal = cWeekDataTotal - value;
                            }
                        })
                      }

                      series.value[0].data.push(cWeekDataTotal);
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject(new Error ('getFinanceRecords error => '+response.data.errors));
                });
        });
    }

    onMounted(() => {
      getWeeklyData();
      MenuComponent.reinitialization();
    });

    return {
      series,
      chartOptions,
    };
  },
});
</script>
