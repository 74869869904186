
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import StudentActivities from "./dashboard/StudentActivities.vue";
import QuickLink from "./dashboard/QuickLink.vue";
import Calendar from "./widgets/Calendar.vue";
import TopInformation from "./dashboard/TopInformation.vue"
import CreateRecord from "@/views/financial/modals/CreateRecord.vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "dashboard",
  components: {
    StudentActivities,
    QuickLink,
    Calendar,
    TopInformation,
    CreateRecord
  },
  setup() {
    const transactionTypes = ref<any>([]);
    const paymentMethods = ref<any>([]);
    const paymentTypes = ref<any>([]);
    const students = ref<any>([]);
    const store = useStore();   
    const user = store.getters.currentUser; 
    
    const getTypes = async() => {
        ApiService.setHeader();
        await ApiService.get("finance/types")
                .then(({ data }) => {
                    transactionTypes.value = data.recordTypes;
                    paymentMethods.value = data.paymentMethods;
                    paymentTypes.value = data.paymentTypes;
                })
                .catch(({ response }) => {
                console.log('getTypes error => '+response.data.errors);
                });
    }

    const getStudents = async() =>{ 
        ApiService.setHeader();
        await ApiService.get("student/status/Active,Prospective,COS,Initial,Reinstate,Exit")
                .then(({ data }) => {
                    students.value = data.data;
                })
                .catch(({ response }) => {
                console.log('getStudents error => '+response.data.errors);
                });
    }

    onMounted(async() => {      
      await getStudents();
      await getTypes();
      setCurrentPageTitle("Dashboard");
    });

    return {
      transactionTypes,
      paymentMethods,
      paymentTypes,
      students,  
      user
    }
  },
});
