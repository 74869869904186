
import { defineComponent, onBeforeMount, onMounted, reactive, ref, resolveComponent } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { object } from "yup/lib/locale";

export default defineComponent({
  name: "student-activities",
  props: {
    widgetClasses: String,
  },
  components: {
  },
  setup() {
    const memos = reactive<any>([]);
    const memoList = ref<any>([]);
    const noOfRecords = 30;
    const getStudentMemo = async () =>  {
      return new Promise<void>((resolve, reject) => { 
        ApiService.setHeader();         
        ApiService.get("memo/student")
            .then(({ data }) => {
                memoList.value = data.data;
                resolve();
            })
            .catch(({ response }) => {
                console.log('getStudentMemo error => '+response.data.errors);                    
            });
      });
    }

    const getStudentDeposit = async () =>  {
      return new Promise<void>((resolve, reject) => { 
        ApiService.setHeader();         
        ApiService.get("finance/records/student-deposit/"+noOfRecords)
            .then(({ data }) => {
                data.data.map(x=> memoList.value.push({ id:'rec'+x.id, create_date : x.record_date, memo: "Deposit : $"+x.amount, student : x.student}));
                resolve();
            })
            .catch(({ response }) => {
                console.log('getUsers error => '+response.data.errors);                    
            });
      })
    }

    onBeforeMount(async() => {
      await getStudentMemo().then(
        async x => await getStudentDeposit().then(x=> {
          memoList.value = memoList.value.sort(function(a,b) { 
            if(a.id == b.id) return 0;

            if (Date.parse(a.create_date!) < Date.parse(b.create_date!))
                return 1;
            if (Date.parse(a.create_date!) > Date.parse(b.create_date!))
                return -1;
            return 0;
          })
        }))
      Object.assign(memos, memoList.value.slice(0, 30));
    });

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return{
      memos,
      moment
    }
  },
});
