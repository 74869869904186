
import { defineComponent, onMounted, ref } from "vue";
import moment from "moment";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "widget-1",
  components: {
  },
  setup() {
    const totalDeposit = ref(0.00);
    const totalNoOfActiveStudents = ref(0);
    const today = moment().format('YYYY-MM-DD');
    const getTodayTotalDeposit = async() =>{
      await new Promise<void>((resolve, reject) => {   
            ApiService.setHeader();         
            ApiService.get("finance/records/"+today+"/"+today)
                .then(({ data }) => {            
                    let totalCurrentDeposit = 0;
                    if(data.data){
                      data.data.forEach(record => {
                            let value = parseFloat(record.amount);                                
                            if(record.record_type == 'Deposit'){
                                totalCurrentDeposit = totalCurrentDeposit + value;
                            }
                      })
                    }
                    totalDeposit.value = totalCurrentDeposit;
                    resolve();
                })
                .catch(({ response }) => {
                    reject(new Error ('getFinanceRecords error => '+response.data.errors));
                });
        });
    }

    const getTotalNoOfActiveStudents = async() =>{
      await new Promise<void>((resolve, reject) => {   
            ApiService.setHeader();         
            ApiService.get("student/status/Active")
            .then(({ data }) => {
                totalNoOfActiveStudents.value = data.data.length;
            })
            .catch(({ response }) => {
              console.log('getStudents error => '+response.data.errors);
            });
        });
    }
    
    onMounted(() => {
      getTodayTotalDeposit();
      getTotalNoOfActiveStudents();
    });

    return {
      totalDeposit,
      totalNoOfActiveStudents,
    };
  },
});
